<template>
  <v-row align="center" class="list">
    <v-col cols="12" sm="12">
      <v-data-table :mobile-breakpoint="0" class="iconTable" :headers="headers" :items="teams.data" disable-pagination
        :hide-default-footer="true" :loading="fetchingData" :loading-text="'Cargando... Espere por favor'"
        :no-data-text="'datos no disponibles'" item-key="_id" hide-default-header>
        <template #header="{ props: { headers } }">
          <thead class="v-data-table-header" style="background-color: #299F7E;">
            <tr>
              <template v-for="header in headers">
                <th :key="header.value" :class="header.align">
                  <v-row>
                    <v-col cols="12">
                      <h2 style="color: white">
                        {{ header.text }}
                      </h2>
                    </v-col>
                  </v-row>
                </th>
              </template>
            </tr>
          </thead>
        </template>
        <template #[`item.name`]="{ item }">
          <td :style="
            teams.data.indexOf(item) % 2 != 0
              ? 'background:#75D89F1A!important'
              : ''
          ">
            {{ item.name }}
          </td>
        </template>
        <template #[`item.continent_id`]="{ item }">
          <td :style="
            teams.data.indexOf(item) % 2 != 0
              ? 'background:#75D89F1A!important'
              : ''
          "
          >
            {{ item.continent ? item.continent.name : '' }}
          </td>
        </template>
        <template #[`item.fifa_postion`]="{ item }">
          <td :style="
            teams.data.indexOf(item) % 2 != 0
              ? 'background:#75D89F1A!important'
              : ''
          ">
            {{ item.fifa_postion }}
          </td>
        </template>
        <template v-slot:[`item.photo_url`]="{ item }">
          <td :style="
            teams.data.indexOf(item) % 2 != 0
              ? 'background:#75D89F1A!important'
              : ''
          ">
            <div class="p-2 mt-1 mb-1" v-if="item.photo_url">
              <v-img :src="item.photo_url" :alt="item.name" width="70px"></v-img>
            </div>
            <div class="p-2 mt-1 mb-1" v-else>
              <v-img src="@/assets/images/person.jpg" alt="" width="70px"></v-img>
            </div>
          </td>
        </template>
        <template #[`item.detail`]="{ item }">
          <td :style="
            teams.data.indexOf(item) % 2 != 0
              ? 'background:#75D89F1A!important'
              : ''
          ">
           <PlayersModal :team="item"> </PlayersModal>
          </td>
        </template>
        <template #[`item.actions`]="{ item }">
          <td :style="
            teams.data.indexOf(item) % 2 != 0
              ? 'background:#75D89F1A!important'
              : ''
          ">
            <v-btn icon color="primary" :to="'/datos/edit/' + item.id" >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </td>
        </template>
      </v-data-table>
    </v-col>

    <v-col cols="12" sm="12">
      <v-row>
        <v-col cols="12" sm="12" class="d-flex justify-end">
          <v-pagination v-model="page" :length="teams.last_page" total-visible="7" next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left" @input="handlePageChange" circle></v-pagination>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions } from "vuex";
import PlayersModal from "../modals/PlayersModal.vue";
export default {
  name: "table-team",
  components : {
    PlayersModal
  },
  watch: {
    text: function (value) {
      this.getFetch();
    },
  },
  data: () => ({
    headers: [
      { text: "Pais", value: "name", width: 150, sortable: false },
      { text: "Continente", value: "continent_id", width: 200, sortable: false },
      { text: "Ranking FIFA", value: "fifa_postion", sortable: false },
      { text: "Foto", value: "photo_url", sortable: false },
      { text: "Jugadores", value: "detail", sortable: false },
      { text: "Modificar", value: "actions", sortable: false },
    ],
    page: 1,
    totalPages: 0,
    pageSizes: [10, 20, 30],
    pageSize: 10,
    //   loading: false
  }),
  computed: {
    ...mapState({
      teams: (state) => state.team.teams,
      fetchingData: (state) => state.fetchingData,
    }),
    text() {
      return this.$route.query.text;
    },
  },
  methods: {
    ...mapActions(["fetchTeams"]),
    getRequestParams(page, pageSize) {
      let params = {};

      if (this.text) {
        params["search"] = this.text;
      }
      if (page) {
        params["page"] = page - 1;
      }
      if (pageSize) {
        params["per_page"] = pageSize;
      }
      return params;
    },
    handlePageChange(value) {
      this.page = value;
      this.getFetch();
      //this.retrieveTutorials();
    },

    handlePageSizeChange(size) {
      this.pageSize = size;
      this.page = 1;
      this.getFetch();
      //this.retrieveTutorials();
    },
    getFetch() {
      const params = this.getRequestParams(this.page, this.pageSize);
      this.fetchTeams({ params: params });
    },
    // getCurrentYear() {
    //   return new Date().getFullYear();
    // },
  },
  created() {
    this.getFetch();
  },
};
</script>

<style>
</style>