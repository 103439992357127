<template>
    <v-dialog v-model="dialog" width="1200">
        <template v-slot:activator="{ on, attrs }">
            <!-- <v-btn color="red lighten-2" dark v-bind="attrs" v-on="on">
                Click Me
            </v-btn> -->
            <a v-bind="attrs" v-on="on">
                Ver lista
            </a>
        </template>

        <v-card class="px-5 pt-5 pb-5">
            <v-card-title class="justify-left d-flex flex-column pb-2 align-start">
                <h2 class="text-uppercase">{{ team.name }}</h2>
                <div class="pb-2"></div>
                <h3>EQUIPO</h3>
            </v-card-title>

            <v-card-text>
                <v-container fluid grid-list-md >
                    <v-layout row wrap>
                        <v-flex xs12 md4 v-for="(player, index) in orderPlayer" :key="index">
                            <div class="d-flex flex-row pa-4 align-center">
                                <p class="font-weight-bold px-3">{{ player.number }}</p>
                                <v-img :src="player.photo_url" :alt="player.photo_name" max-height="72"
                                    max-width="72"
                                    ></v-img>
                                <div class="d-flex flex-column px-3">
                                    <p class="font-weight-bold">{{ player.full_name }}</p>
                                    <p>{{ player.player_position.name }}</p>
                                </div>
                            </div>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-card-text>


        </v-card>
    </v-dialog>
</template>

<script>
import _orderBy from 'lodash/orderBy';

export default {
    name: 'ModalPlayers',
    props: ['team'],
    data: () => ({
        dialog: false
    }),
    computed : {
        orderPlayer : function(){
            return _orderBy(this.team.players, ['number'], ['asc']);
        }
    }
}
</script>

<style>
</style>